var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
// var countUpStatsDuration = 3000;
var subsiteHijackMainNav = ['epic-awards'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Reposition feed into to a section at the end
if ($('body').hasClass('homepage')) {
  $('.homeFeed').each(function(){
    var $thisIntro = $(this).find('.feedsTitle ~ *:not(.feedList)');
    $thisIntro.insertAfter($(this).find('.feedItem:last-of-type')).wrapAll('<li class="feedItem feedSeeMore"></li>');
    var thisFeed = $(this).find('.feedSeeMore');
    var seeMoreURL = thisFeed.find('.cta-button').attr('href');
    thisFeed.attr('onclick', `window.location="${seeMoreURL}"`);
  })
}

// Home feature sections
$('.homeBox1, .homeBox2').wrapAll('<div class="featureSection featureSection1"></div>');
$('.homeBox3, .homeBox4').wrapAll('<div class="featureSection featureSection2"></div>');
$('.homeBox5, .homeBox6').wrapAll('<div class="featureSection featureSection2"></div>');

if ($('body').hasClass('homepage')) {
  $('.featureSection').each(function(){
    $(this).find('.homeFeatureSnippets > *').wrapAll('<div class="snippetText"></div>');
  })
}

// Padding calculation for inline nav
function inlineNavPadding() {
  if ($('html').width() > 1024) {
    var padding = parseInt($('.mainCallToAction ').first().css('width')) + parseInt($('#siteSearch').first().css('width')) + 45; 
  } else {
    var padding = 0;
  }
  $('.pageHeader .menuMain').css('padding-right', padding); 
}
inlineNavPadding();
$( window ).resize(function() {
  inlineNavPadding();
});

// Add class to related posts so they can use card prefab
if ($('.associatedRelatedPosts .associatedListing li')) {
  $('.associatedRelatedPosts .associatedListing li').each(function(){
    $(this).addClass('listedPost');
    $(this).find('h3, p').wrapAll('<div class="listedPostText">');
    $(this).find('h3').replaceWith(function(){
      return '<h2>' + $(this).text() + '</h2>';
    })
  })
}

// Add onclick to related items
$('.associatedRelatedPosts .listedPost').each(function(){
  let thisURL = $(this).find('.associated-image-link').attr('href');
  $(this).attr('onclick', `window.location = "${thisURL}"`);
})

// Thing to make blockquote attributes easier
if (($('body[class*="PostBody"]').length = 1)) {
  var blockquotes = document.querySelectorAll("blockquote");
  for (var i = 0; i < blockquotes.length; i++) {
    if (blockquotes[i].innerHTML.includes("quote-attribute")) {
      blockquotes[i].classList.add("contains-attribution");
    }
  }
}

// Old bespoke JS
// check to see if it is a subsite

if ($('.subsite').length > 0) {

  // Move subsite menu
  $('.headerWrapperSubsite').append($('nav.menuSub'));

}

$('.mainCallToAction > .socialIcons:first-of-type').clone().css('margin-bottom', '1rem').prependTo('.footerBox4');